var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "article-grid" },
    [
      _c("category-block", {
        staticClass: "category-block category-block-1",
        attrs: { target: _vm.category, position: "position1" }
      }),
      _c(
        "v-container",
        [
          _c("CategoryTitle", {
            attrs: { category: _vm.category, count: _vm.count }
          }),
          _vm.mergedFilterData.length > 0
            ? _c(
                "div",
                { staticClass: "mx-n2" },
                [
                  _c(
                    "v-chip-group",
                    {
                      staticClass: "my-2",
                      attrs: { "active-class": "active-chip" },
                      model: {
                        value: _vm.selectedClass,
                        callback: function($$v) {
                          _vm.selectedClass = $$v
                        },
                        expression: "selectedClass"
                      }
                    },
                    [
                      _c(
                        "v-chip",
                        {
                          attrs: { value: null },
                          on: {
                            click: function($event) {
                              return _vm.handleFilterChangeChip(null)
                            }
                          }
                        },
                        [_vm._v("Tutti")]
                      ),
                      _vm._l(_vm.mergedFilterData, function(tag) {
                        return _c(
                          "v-chip",
                          {
                            key: tag.id,
                            class: {
                              "active-chip": tag.id == _vm.selectedClass
                            },
                            attrs: { value: tag.id },
                            on: {
                              click: function($event) {
                                return _vm.handleFilterChangeChip(tag.id)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(tag.description) + " ")]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            _vm._l(_vm.articleList, function(article) {
              return _c(
                "v-col",
                {
                  key: article.id,
                  attrs: { cols: "6", sm: "4", md: "3", lg: "2" }
                },
                [_c("ArticleCard", { attrs: { article: article } })],
                1
              )
            }),
            1
          ),
          _vm.hasMoreItems()
            ? _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "intersect",
                      rawName: "v-intersect",
                      value: _vm.onIntersect,
                      expression: "onIntersect"
                    }
                  ],
                  staticClass: "mt-3",
                  attrs: {
                    block: "",
                    text: "",
                    large: "",
                    depressed: "",
                    rounded: "",
                    color: "primary",
                    loading: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.loadMore()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("recipes.showMore")))]
              )
            : _vm._e()
        ],
        1
      ),
      _c("category-block", {
        staticClass: "category-block category-block-2",
        attrs: { target: _vm.category, position: "position2" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }